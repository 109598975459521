<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Y.T.I.P Diploma</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <div class="iq_content_wrap">
      <!--About Us START-->
      <section class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="text">
                <!--Heading Wrap Start-->
                <div class="iq_heading_1 text-left">
                  <h4>YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY <span>(DIPLOMA)</span></h4>
                </div>
                <p>DTE CODE-DPH3296</p>
                <p>Recognized by P. C.I Approved by AICTE / DTE-DPH 3296, <br>
                  Affiliation to MSBTE</p>
                <!--Heading Wrap End-->
                <p>
                  Saraswati Education society (SES) is established in the year 2003. The chairman of trust Dr. N.Y Tasgaonkar himself is renowned “Doctor, under his dynamic leadership all Institutes a reprocessing by keeping pace with the world. Today SES is known as leading Educational group in Maharashtra in a very short span of 07years establishing Institutes such as colleges Engineering, Managements, Pharmacy (Degree & Diploma) and polytechnic colleges. Campus amidst the foots of Matheran near Bhivpuri road station surrounded by Sahyadri mountains and right on the banks of Ulhas river with state of art modern facilities.
                </p>
                <p v-if="false">
                  Faculty List: <span class="text-green"><a target="_blank" style="color:#62a13a!important;" href="https://ytipharmacy.com/files/diploma.pdf"><b>View</b></a></span>
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="text-center">
                <img height="300px" src="images/logo.png" alt="about_us">
              </div>
            </div>
          </div>
        </div>
      </section>

      <PrincipalMessage :principe-img="principleImg">
        <div class="iq_knowledge_des">
          <!--Heading Wrap Start-->
          <div class="iq_heading_1 text-left">
            <h4>FROM THE DESK OF <span>THE PRINCIPAL</span></h4>
          </div>
          <!--Heading Wrap End-->
          <p>It gives me a immense pleasure to introduce SARASWATI EDUCATION SOCIETY’S YADAVRAO TASGAONKAR INSTITUTE OF
            PHARMACY. The commitment for total quality management and discipline is a Prime Slogan and Motto of our
            College. The Vision of YTIP is to create competent Pharmacy. Professional to positively impact healthcare of
            society, we plan to achieve this by mentoring our student so that they overcome challenges in the health
            care industry with knowledge, skill, courage, confidence and integrity. It is only whole hearted support,
            cooperation and confidence of the management and staff in us that we will be able to fulfill our mission of
            provide new dimensions to education. The institute is approved by All India council of technical Education,
            Pharmacy council of India and is affiliated to Maharashtra state board of technical education.</p>
          <p>Presently college has 5 Laboratories having all equipment and machinery for pharmacy education we also have
            facilities such as library, computer lab with free internet access. We have other amenities such as canteen,
            indoor games, common room and hostel for girls and boys, medical facilities available all time. I hope, you
            will visit institute, Observe and experience the academic and other facilities and interact our management,
            staff, students.</p>
          <h5 class="mb-0">Ms. Pooja Kangane</h5>
          <p>Principal YTIP Diploma</p>
        </div>
      </PrincipalMessage>

      <section>
        <div class="container">
          <div class="mandatory-disclosures">
            <div class="iq_heading_1 text-left">
              <h4>SIF Report</h4>
            </div>
            <p>
              SIF Report 2021-22 <a class="text-success" href="/docs/SIF Report.pdf" target="_blank"><b>View</b></a>
            </p>
          </div>
        </div>
      </section>

      <section class="dark-bg">
        <div class="container">
          <div class="mandatory-disclosures">
            <div class="iq_heading_1 text-left">
              <h4>Mandatory disclosures</h4>
            </div>
            <p>
              Mandatory disclosures as per AICTE NORMS 2021-22 <a class="text-success" href="/docs/Mandatory disclosures Diploma.pdf" target="_blank"><b>View</b></a>
            </p>
          </div>
        </div>
      </section>

    </div>

  </div>
</template>

<script>
import PrincipalMessage from '../components/PrincipalMessage'
import principleImg from '../assets/images/degree-principle.png'

export default {
  data: function () {
    return {
      principleImg: principleImg
    }
  },
  components: {
    PrincipalMessage
  }
}
</script>
