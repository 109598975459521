<template>
  <section class="about-us-section dark-bg">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="thumb">
          <img :src="principeImg" alt="Rupali Tasgaonkar">
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrincipalMessage',
  props: {
    principeImg: String
  }
}
</script>

<style>
.dark-bg {
  background-color: #f0f0f0;
}
</style>
